import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    background-color: #0B2B40;
    -webkit-font-smoothing: antialiased
  }
  body, input, button {
    font: 16px "Roboto Flex", sans-serif;
    color: #f0eff4;
  }
  button {
    cursor: pointer;
  }
`
