import GlobalStyle from "./Layouts/Styles/global"
import { Title1, Wrapper } from "./Layouts/Styles/HtmlElements"
import { ConstructionIcon } from "./Layouts/Styles/images"
import { useEffect } from "react"

function App() {
  const language_message = () => {
    switch (navigator.languages[0]) {
      case "pt-BR" || "pt-PT":
        return [
          "Em breve",
          "Estamos trabalhando nessa página e logo estará disponível!",
        ]
      case "en" ||
        "en-US" ||
        "en-GB" ||
        "en-CA" ||
        "en-AU" ||
        "en-NZ" ||
        "en-ZA":
        return [
          "Coming soon",
          "We are curently working on this page and will launch soon!",
        ]
      case "es-ES" ||
        "es-MX" ||
        "es-AR" ||
        "es-CL" ||
        "es-CO" ||
        "es-PE" ||
        "es-VE" ||
        "es-EC" ||
        "es-UY" ||
        "es-PY" ||
        "es-BO" ||
        "es-SV" ||
        "es-HN" ||
        "es-NI" ||
        "es-PR":
        return [
          "Próximamente",
          "Estamos trabajando en esta página y pronto estará disponible!",
        ]
      case "fr-FR" || "fr-CA" || "fr-BE" || "fr-CH" || "fr-LU" || "fr-MC":
        return [
          "Bientôt disponible",
          "Nous travaillons actuellement sur cette page et la lancerons bientôt!",
        ]
      case "de-DE" || "de-AT" || "de-CH" || "de-LU" || "de-LI":
        return [
          "Demnächst",
          "Wir arbeiten derzeit an dieser Seite und werden bald starten!",
        ]
      case "it-IT" || "it-CH":
        return [
          "Prossimamente",
          "Stiamo lavorando su questa pagina e la lanceremo presto!",
        ]
      case "ja-JP" || "ja-JP-u-ca-japanese-x-lvariant-JP":
        return [
          "近日公開",
          "このページについて現在作業中です。すぐに公開します！",
        ]
      case "ko-KR" || "ko-KP":
        return [
          "곧 출시",
          "현재 이 페이지에 대한 작업을 진행 중이며 곧 출시됩니다!",
        ]
      case "ru-RU":
        return [
          "Скоро",
          "Мы в настоящее время работаем над этой страницей и скоро запустим!",
        ]
      case "zh-CN" || "zh-SG":
        return ["即将上线", "我们正在努力开发这个页面，敬请期待！"]
      case "zh-TW" || "zh-HK":
        return ["即將上線", "我們正在努力開發這個頁面，敬請期待！"]
      default:
        return [
          "Coming soon",
          "We are curently working on this page and will launch soon!",
        ]
    }
  }
  let message = language_message()
  useEffect(() => {
    document.title = message[0]
  })
  return (
    <Wrapper>
      <GlobalStyle />
      <ConstructionIcon />
      <Title1>{message[0]}</Title1>
      <h2>{message[1]}</h2>
    </Wrapper>
  )
}

export default App
